<template>
  <div class="grid place-items-center pt-5" style="padding-top: 25%">
    <img src="https://api.youschool.io/media/637fd694-be65-43cb-8829-faf54428ad52/render/image_hq" class="w-140 mb-4 h-40 rounded object-cover" />
    <h1 class="pb-5" style="font-size: xxx-large">403</h1>
    <h1 class="font-semibold text-gray-700 group-hover:text-gray-900">Vous n'avez pas accès à cette page.</h1>
    <footer class="mt-auto py-5 text-center">
      <div class="grid place-items-center pt-5">
        <button type="button" class="btn mr-1" @click="backToHome">Retour à la page d'accueil</button>
      </div>
    </footer>
  </div>
</template>
<script setup lang="ts">
const backToHome = () => {
  window.location.href = "/";
};
</script>
